const globalTestimonialData = [
  {
    text: "As an athlete, maintaining optimal health is crucial. Docus has been a game-changer for me. Its AI Doctor is amazing, providing insights that help me fine-tune my training regimen and nutrition. I'm so thrilled to have found Docus.",
    author: 'Eric S.',
    avatarUrl: '/testimonials/testimonial_1.jpg',
  },
  {
    text: "Dealing with health anxiety, every new symptom used to send me down a rabbit hole of worry and speculation. Discovering Docus has been transformative for my mental and physical well-being. Docus AI Doctor provides clear, concise insights, guiding me on when to seek professional help. It's been an essential tool in managing my health anxiety, helping me approach my concerns with calm and confidence.",
    author: 'Alex T.',
    avatarUrl: '/testimonials/testimonial_2.jpg',
  },
  {
    text: 'Docus enabled me to conveniently secure a second opinion from a top US doctor, saving both time and travel expenses. I appreciate the seamless and hassle-free experience they provided, ensuring access to expert medical advice.',
    author: 'Casey R.',
    avatarUrl: '/testimonials/testimonial_3.jpg',
  },
  {
    text: 'The speed and accuracy of the Docus AI Health Assistant were truly impressive. It provided me with the confidence I needed to proceed with my treatment, knowing that I had reliable guidance and support along the way.',
    author: 'Peter S.',
    avatarUrl: '/testimonials/testimonial_4.jpg',
  },
  {
    text: "Entering menopause was a bit daunting with all the changes my body started to go through. The Docus AI Doctor helped me identify my symptoms like hot flashes and mood swings as menopausal. Knowing what was happening gave me peace of mind and the confidence to seek the right treatment. I'm grateful for such a supportive tool.",
    author: 'Aisha H.',
    avatarUrl: '/testimonials/testimonial_5.jpg',
  },
  {
    text: "My son had recurring stomach aches, and I wasn't sure if it was his diet or something more serious. Docus AI Doctor suggested possible food intolerance and recommended seeing a specialist. It turned out to be lactose intolerance, and we've since adjusted his diet. I'm so grateful for the guidance that led us to find a solution.",
    author: 'Sofia R.',
    avatarUrl: '/testimonials/testimonial_6.jpg',
  },
];

export const testimonialsSectionData = {
  home: {
    title: 'Our Users Love Us',
    text: 'Discover how Docus has transformed the way people understand and manage their health.',
    testimonialData: globalTestimonialData,
  },
  'ai-health-assistant': {
    title: 'Trusted Life changing Secure',
    text: 'Discover how Docus Symptom Checker has transformed the way people understand and manage their health.',
    testimonialData: globalTestimonialData,
  },
  consultation: {
    title: 'Our Users Love Us',
    text: 'Discover how Docus has transformed the way people understand and manage their health.',
    testimonialData: globalTestimonialData,
  },
  'ai-doctor': {
    title: 'Hear From Our Users',
    text: 'Discover how our AI Doctor has positively impacted lives.',
    testimonialData: [
      {
        text: "I've never felt more empowered in my health journey than I do with Docus AI Doctor. It provides instant, reliable health insights that are easy to understand. It's not intended to replace my doctor, but it complements my healthcare routine perfectly by offering a readily available second opinion. Everyone should use this!",
        author: 'Monica A.',
        avatarUrl: '/testimonials/AI_Doctor_Testimonial_1.jpg',
      },
      {
        text: "Docus AI Doctor has made a significant difference in the way I approach my health. 24/7 it provides prompt, understandable responses to my health concerns. It's given me peace of mind and empowered me to be more proactive about my health. Truly, an invaluable tool for anyone interested in their health and well-being.",
        author: 'Harry L.',
        avatarUrl: '/testimonials/AI_Doctor_Testimonial_2.jpg',
      },
      {
        text: "Using AI Doctor has been a game-changer for me. I love that I can ask health-related questions anytime, from anywhere. It's like having a virtual health assistant in my pocket. It's not only convenient, but it also makes understanding complex health topics easier.",
        author: 'Emily G.',
        avatarUrl: '/testimonials/AI_Doctor_Testimonial_3.jpg',
      },
    ],
  },
  'symptom-checker': {
    title: 'Our Users Love Us',
    text: 'Discover how Docus Symptom Checker has transformed the way people understand and manage their health.',
    testimonialData: [
      {
        text: 'I was worried about some symptoms I was experiencing but didn’t know if they were serious. Using Docus gave me quick insights and suggested possible conditions, which was a great relief. It encouraged me to seek medical advice promptly, and thankfully, I caught my condition early.',
        avatarUrl: '/symptom-checker/morgan-avatar.png',
        author: 'Morgan S.',
      },
      {
        text: 'Before discovering Docus, I felt overwhelmed by the health information online. The Symptom Checker not only clarified my symptoms but also provided peace of mind. Now, I approach my health discussions with my doctor feeling much more informed and empowered.',
        avatarUrl: '/symptom-checker/casey-avatar.png',
        author: 'Casey R.',
      },
      {
        text: "Dealing with health anxiety, every new symptom used to send me down a rabbit hole of worry and speculation. Discovering Docus has been transformative for my mental and physical well-being. The Symptom Checker provides clear, concise insights, guiding me on when to seek professional help. It's been an essential tool in managing my health anxiety, helping me approach my concerns with calm and confidence.",
        avatarUrl: '/symptom-checker/alex-avatar.png',
        author: 'Alex T.',
      },
    ],
  },
  'symptom-checker-for-adults': {
    title: 'Real Stories from Our Users',
    text: 'Discover how the Docus AI Symptom Checker has made a difference in the lives of adults across various life stages.',
    testimonialData: [
      {
        text: 'As someone navigating a hectic lifestyle, the Docus Symptom Checker has been a revelation. It helped me understand the stress-related symptoms I was experiencing and guided me towards making healthier lifestyle choices. Having a health advisor available 24/7 is incredibly reassuring.',
        avatarUrl: '/symptom-checker/for-adults/adult1-Linda-S.jpg',
        author: 'Linda S., 33 yo',
      },
      {
        text: 'Turning 50 was a wake-up call to take my health more seriously, especially with a family history of heart disease. The Docus Symptom Checker became my go-to for monitoring symptoms and understanding potential health issues. Its ease of use and accurate insights have made health management simpler and less daunting.',
        avatarUrl: '/symptom-checker/for-adults/adult2-David-T.jpg',
        author: 'David T., 51 yo',
      },
      {
        text: "Docus Symptom Checker has empowered me to manage my arthritis symptoms effectively and maintain my independence. The tool's focus on adult health, across all stages, truly stands out. It's reassuring to have reliable support as I embrace these years.",
        avatarUrl: '/symptom-checker/for-adults/adult3-Mia-K.jpg',
        author: 'Mia K., 67 yo',
      },
    ],
  },
  'symptom-checker-for-men': {
    title: 'Voices of Trust',
    text: "Read how the Docus Symptom Checker for Men has provided clarity, support, and actionable insights into men's health concerns.",
    testimonialData: [
      {
        text: `I used the Symptom Checker after weeks of dismissing my persistent fatigue as just overwork. To my surprise, it suggested sleep apnea, something I hadn't considered but matched my symptoms. Following up with a specialist confirmed it. Using this tool led me to a diagnosis I hadn't even considered.`,
        avatarUrl: '/symptom-checker/for-men/Man1-Derek.jpg',
        author: 'Derek H., 40 yo',
      },
      {
        text: 'When I entered symptoms related to urinary discomfort, it provided clear, stigma-free guidance on potential prostate concerns and urged me to see a urologist. It was the advice I needed! Docus Symptom Checker for Men is an invaluable tool for every man who wants to be proactive about his health.',
        avatarUrl: '/symptom-checker/for-men/Man2-Liam.jpg',
        author: 'Liam S., 54 yo',
      },
      {
        text: 'After experiencing a mix of multiple symptoms like weight changes and low mood, I was confused and concerned. The Symptom Checker for Men helped me piece together these symptoms in a way that made sense, suggesting a possible thyroid issue. It broke down the health jargon and made it easy for me to communicate my concerns with my doctor, leading to the right tests and eventually, the right treatment.',
        avatarUrl: '/symptom-checker/for-men/Man3-Carlos.jpg',
        author: 'Carlos M., 36 yo',
      },
    ],
  },
  'symptom-checker-for-women': {
    title: 'Health Journeys Shared',
    text: 'Hear from women who have taken steps toward better health with the help of the Docus AI Symptom Checker.',
    testimonialData: [
      {
        text: "Entering menopause was a bit daunting with all the changes my body started to go through. The Docus AI Symptom Checker helped me identify my symptoms like hot flashes and mood swings as menopausal. Knowing what was happening gave me peace of mind and the confidence to seek the right treatment. I'm grateful for such a supportive tool.",
        avatarUrl: '/symptom-checker/for-women/Women1-Aisha.jpg',
        author: 'Aisha H., 45 yo',
      },
      {
        text: 'After struggling with irregular periods and discomfort for months, I decided to use the Symptom Checker for Women. It was incredibly easy to use and suggested I might have PCOS. Armed with this information, I felt more prepared to discuss my symptoms with my doctor, who confirmed the diagnosis.',
        avatarUrl: '/symptom-checker/for-women/Women2-Jasmine.jpg',
        author: 'Jasmine T., 39 yo',
      },
      {
        text: "I found Docus experiencing discomfort and pain during sex, something I was too embarrassed to talk about initially. The symptom checker suggested a possible yeast infection. It encouraged me to seek medical advice without fear. Thank you for making women's health a priority and for providing a platform that offers privacy and support.",
        avatarUrl: '/symptom-checker/for-women/Women3-Emma.jpg',
        author: 'Emma P., 27 yo',
      },
    ],
  },
  'child-symptom-checker': {
    title: 'From Parents Like You',
    text: 'Hear how our Symptom Checker has become a trusted resource for families navigating their children’s health symptoms.',
    testimonialData: [
      {
        text: "I was up late one night worried about my youngest's persistent cough. The Child Symptom Checker helped me understand it might be more than just a cold. Following its advice, we visited our pediatrician the next day and caught bronchitis early. This tool was invaluable for taking the right steps quickly.",
        avatarUrl: '/symptom-checker/child-symptom-checker/Child1-Maria.jpg',
        author: 'Maria L., mother of two',
      },
      {
        text: 'When my daughter had a high fever and rash, I felt overwhelmed. Using the Docus Symptom Checker gave us clear insights and suggested it could be related to a common childhood virus. Knowing this, we were better prepared to discuss our concerns with our doctor, who confirmed the diagnosis. This tool provided peace of mind in a stressful situation.',
        avatarUrl: '/symptom-checker/child-symptom-checker/Child2-Michael.jpg',
        author: 'Michael T., first-time dad',
      },
      {
        text: "My son had recurring stomach aches, and I wasn't sure if it was his diet or something more serious. The symptom checker suggested possible food intolerance and recommended seeing a specialist. It turned out to be lactose intolerance, and we've since adjusted his diet. I'm so grateful for the guidance that led us to find a solution.",
        avatarUrl: '/symptom-checker/child-symptom-checker/Child3-Sofia.jpg',
        author: 'Sofia R., mother of three',
      },
    ],
  },
  supplements: {
    title: 'Customer Feedback',
    text: 'Hear from our satisfied customers who have experienced the benefits of Docus supplements.',
    testimonialData: [
      {
        text: "Since using my personalized supplement kit, I've felt a significant boost in my energy levels and overall wellness. Now I fully trust Docus for my supplement needs. Thank you!",
        avatarUrl: '/supplements/DorothyA.jpg',
        author: 'Dorothy A.',
      },
      {
        text: 'The process was simple and the results have been amazing. My custom supplements have helped me feel more balanced and healthier every day.',
        avatarUrl: '/supplements/MatthewR.jpg',
        author: 'Matthew R.',
      },
      {
        text: 'I was skeptical at first, but the quality of the supplements and the noticeable improvement in my health have made me a believer. Docus really knows my health needs and has provided me with exactly what I was missing.',
        avatarUrl: '/supplements/JosephK.jpg',
        author: 'Joseph K.',
      },
      {
        text: "From the detailed report to the delivery, everything was seamless and hassle-free. I've never felt better, and I owe it all to the personalized approach from Docus. My sleep issues have been resolved, and I feel more rested and energetic than ever.",
        avatarUrl: '/supplements/NancyL.jpg',
        author: 'Nancy L.',
      },
    ],
  },
  'lab-test-interpretation': {
    title: 'Hear from Our Users',
    text: 'Discover the experiences of real users who have transformed their health with our AI-driven lab test interpretations.',
    testimonialData: [
      {
        text: "After uploading my blood test results to Docus, I was amazed to see the detailed analysis pointing out my elevated cholesterol levels. The report explained the risks associated with this and offered practical dietary recommendations. It's been enlightening to see such precise insights about my health.",
        avatarUrl: '/lab-test-interpretation/Robert-S.jpg',
        author: 'James, 42 y.o.',
      },
      {
        text: 'After my Pap smear results came back unclear, I used Docus for a second opinion. The analysis confirmed HPV presence and explained its implications clearly. Based on the detailed insights, I was advised to consult a gynecologist for further management. This guidance was incredibly valuable, helping me approach my follow-up appointments with confidence and clear understanding.',
        avatarUrl: '/lab-test-interpretation/Anita.jpg',
        author: 'Anita, 29 y.o.',
      },
      {
        text: 'I never knew how my diet affected my kidney function until I used Docus for my urine tests. The clear explanations helped me understand my slightly abnormal results and the importance of hydration and diet adjustments. This service has been an eye-opener for managing my health proactively.',
        avatarUrl: '/lab-test-interpretation/Barbara-T.jpg',
        author: 'Maria, 35 y.o.',
      },
      {
        text: 'Docus AI Doctor identified a bacterial infection from my stool and swab tests, which I had missed. The detailed report advised seeing a specialist, which I did immediately. This timely advice was crucial for my treatment.',
        avatarUrl: '/lab-test-interpretation/Elias-M.jpg',
        author: 'Derek, 54 y.o.',
      },
    ],
  },
  'blood-test': {
    title: 'Real Results, Real Stories',
    text: 'Discover how Docus blood test interpretations have transformed the health journeys of our users.',
    testimonialData: [
      {
        text: "After experiencing unexplained weight changes, I decided to discuss the symptoms with Docus AI Doctor. It recommended a thyroid test. I took the test and uploaded the results to Docus. The analysis revealed a high probability of hypothyroidism and led me to consult an endocrinologist, who confirmed the diagnosis and helped me start a targeted treatment plan. I'm grateful for how Docus AI Doctor made navigating my health concerns simpler and more effective.",
        avatarUrl: '/lab-test-interpretation/blood-test/Maria.jpg',
        author: 'Maria, 35 y.o.',
      },
      {
        text: "I was feeling constantly tired and just not myself. The blood test analysis through Docus AI Doctor revealed I had severe Vitamin D deficiency. Following their recommendations, I ordered Docus Vitamin D and adjusted my supplements. Since then, I've seen a dramatic improvement in my energy levels and overall mood.",
        avatarUrl: '/lab-test-interpretation/blood-test/Sophie.jpg',
        author: 'Sophie, 29 y.o.',
      },
      {
        text: 'After months of digestive issues with no clear answers, I turned to Docus AI Doctor for help. It suggested a comprehensive metabolic panel. I uploaded my results, and the analysis pinpointed a significant imbalance in my liver enzymes. Armed with this information, I consulted a gastroenterologist who diagnosed me with a mild liver condition and recommended specific dietary changes. The improvement has been incredible.',
        avatarUrl: '/lab-test-interpretation/blood-test/Anil.jpg',
        author: 'Anil, 42 y.o.',
      },
    ],
  },
  'urine-test': {
    title: 'What Our Users Say',
    text: 'Discover how Docus urinalysis interpretation has helped people understand and manage their health.',
    testimonialData: [
      {
        text: 'Frequent urination and intense thirst had been troubling me for weeks. Docus AI Doctor recommended a urinalysis to investigate further. I took the test and uploaded the results to Docus. The analysis revealed high glucose levels, indicating diabetes, prompting me to visit an endocrinologist, who confirmed the diagnosis and helped me start a treatment plan. Docus AI Doctor helped me catch this serious condition early.',
        avatarUrl: '/lab-test-interpretation/urine-test/Sarah.jpg',
        author: 'Sarah M., 54 y.o.',
      },
      {
        text: 'I had a persistent urge to urinate and noticed a foul odor in my urine. Worried, I took a urine and blood test and uploaded the results to Docus AI Doctor. The quick analysis indicated a UTI, and after visiting the specialist, my symptoms have completely cleared up!',
        avatarUrl: '/lab-test-interpretation/urine-test/Kristina.jpg',
        author: 'Kristina O, 39 y.o.',
      },
      {
        text: 'Cloudy urine and a burning sensation while urinating prompted me to take a urine test. I uploaded the results to Docus AI Doctor, which indicated cystitis. A specialist later confirmed the diagnosis. Thanks to the timely analysis from Docus AI Doctor, my symptoms have greatly improved!',
        avatarUrl: '/lab-test-interpretation/urine-test/Jena.jpg',
        author: 'Jena W., 27 y.o.',
      },
    ],
  },
  'pap-smear': {
    title: 'User Experiences',
    text: 'Learn how PAP smear result interpretation has improved the health outcomes of our users:',
    testimonialData: [
      {
        text: "I regularly get PAP smears due to a family history of cervical cancer. Docus AI Doctor's analysis of my most recent test was comprehensive and easy to understand. This time, everything was normal, which was a huge relief.",
        avatarUrl: '/lab-test-interpretation/pap-smear/Linda.jpg',
        author: 'Linda K., 41 y.o.',
      },
      {
        text: "Frequent pelvic pain and unusual vaginal discharge led me to seek help from Docus AI Doctor. It recommended a PAP smear, which I promptly took and uploaded the results. Docus AI's analysis showed abnormal findings. Due to the early detection, I was able to consult with my gynecologist quickly, who diagnosed me with pre-cancerous cells. Early intervention helped prevent cervical cancer, and now my health has greatly improved.",
        avatarUrl: '/lab-test-interpretation/pap-smear/Rachel.jpg',
        author: 'Rachel M., 35 y.o.',
      },
      {
        text: 'I was anxious about getting my first PAP smear and wanted to understand the results quickly. Docus AI Doctor made the process simple and provided a fast, clear explanation of my results, which, fortunately, were normal. Now, I feel much more confident about my health screenings.',
        avatarUrl: '/lab-test-interpretation/pap-smear/Emily.jpg',
        author: 'Emily J., 25 y.o.',
      },
    ],
  },
  'stool-test': {
    title: 'User Experiences',
    text: "See how Docus AI Doctor's stool test result interpretation has positively impacted our users' health:",
    testimonialData: [
      {
        text: 'My son had been suffering from frequent diarrhea and abdominal pain. I needed to find the cause immediately, so I uploaded his stool results to Docus AI Doctor. The analysis detected celiac disease and recommended eliminating gluten from his diet, which was confirmed by his pediatrician. Thanks to Docus AI for the quick and easy explanation!',
        avatarUrl: '/lab-test-interpretation/stool-test/Rachel.jpg',
        author: 'Rachel, 38 y.o. mother',
      },
      {
        text: 'I had persistent nausea, stomach cramps, and noticed weight loss. Worried, I took a stool test and uploaded the results to Docus. The quick analysis indicated a parasitic infection, and after visiting the specialist, my symptoms have completely cleared up!',
        avatarUrl: '/lab-test-interpretation/stool-test/Amy.jpg',
        author: 'Amy, 23 y.o.',
      },
      {
        text: 'Persistent bloating, abdominal pain, and oily stools led me to seek help from Docus AI Doctor. It recommended a fecal elastase test, which I promptly took and uploaded the results. The analysis showed low levels of pancreatic elastase, indicating pancreatic insufficiency. Early intervention and consultation with my therapist helped manage my condition, and now I feel better!',
        avatarUrl: '/lab-test-interpretation/stool-test/Greg.jpg',
        author: 'Greg, 42 y.o.',
      },
    ],
  },
  'semen-analysis': {
    title: 'User Testimonials',
    text: 'Discover how Docus AI spermogram results’ interpretations have transformed the health journeys of our users.',
    testimonialData: [
      {
        text: 'Repeated unsuccessful attempts at conception made me turn to Docus AI Doctor, which recommended taking a male fertility test. The semen analysis indicated poor sperm vitality. Following the recommended lifestyle changes and treatments, my latest test results have improved significantly.',
        avatarUrl: '/lab-test-interpretation/semen-analysis/Brian.jpg',
        author: 'Brian, 36 y.o.',
      },
      {
        text: "Concerned about my reproductive health, I tried to read my semen analysis but couldn't understand it. That's when I found Docus lab test interpretation tool. To my relief, the results showed that my sperm count, motility, and morphology were all good to go. Huge thanks to Docus for the clear explanation. Now I've got peace of mind and can focus on planning for the future.",
        avatarUrl: '/lab-test-interpretation/semen-analysis/Carlos.jpg',
        author: 'Carlos, 39 y.o.',
      },
      {
        text: "My partner and I were anxious about our fertility status. Docus AI Doctor's semen analysis identified an issue with sperm motility. With this information, we consulted a specialist who recommended targeted treatments, and now we are expecting a baby!",
        avatarUrl: '/lab-test-interpretation/semen-analysis/Michael.jpg',
        author: 'Michael, 31 y.o.',
      },
    ],
  },
  'swab-test': {
    title: 'User Feedback',
    text: "Discover how Docus AI Doctor made sense of people's swab test results.",
    testimonialData: [
      {
        text: "Discomfort and irritation in my son's rectal area had been troubling him for a while. Docus AI Doctor suggested a stool test and rectal swab test. The results were full of medical jargon, but the Docus interpretation tool made everything clear. It identified a parasitic infection and advised us to consult a gastroenterologist. With the recommended treatment plan, he felt better in no time. Thanks to Docus for this useful tool!",
        avatarUrl:
          '/lab-test-interpretation/swab-test/miguel-43-y-o-father.jpg',
        author: 'Miguel, 43 y.o., father',
      },
      {
        text: "Docus AI Doctor recommended getting a vaginal swab test due to unusual discharge and itching. When the results came back, there were many complicated bacteria names and numbers, and I couldn't understand any of them. I used the Docus test interpretation tool, and it explained everything and gave me recommendations for the next steps, which my gynecologist confirmed. ",
        avatarUrl: '/lab-test-interpretation/swab-test/amanda-24-y-o.jpg',
        author: 'Amanda, 24 y.o.',
      },
      {
        text: "My daughter got a rash on her face after a dog licked her, and I was really worried. I couldn't make sense of the swab test results, so I uploaded them to Docus. The analysis showed both a bacterial and fungal infection. Thanks to Docus AI Doctor and the advice from our doctor, we managed to clear up the rash quickly.",
        avatarUrl: '/lab-test-interpretation/swab-test/lili-37-y-o-mother.jpg',
        author: 'Lili, 37 y.o., mother',
      },
    ],
  },
};

export type TestimonialsDataByPageName = keyof typeof testimonialsSectionData;
